import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button } from "@mui/material";

export default function Faq() {
  const faqs = [
    {
      que: "What kind of services does Techbrian Networks offer?",
      ans: "Techbrian Networks offers a range of technology solutions and services, including custom software development, web and mobile app development, AI and IoT solutions, cloud services, and digital marketing."
    },
    {
      que: "How does Techbrain Networks ensure the quality of its software solutions?",
      ans: "Techbrain Networks has a team of experienced software developers and quality assurance engineers who follow industry best practices to ensure the quality of our software solutions. We use the latest tools and technologies to test and verify our software to ensure that it meets the highest standards of quality."
    },
    {
      que: "Does Techbrian Networks work with startups?",
      ans: "Yes, we work with startups of all sizes to help bring their ideas to life and provide the necessary tech solutions to help them grow and scale."
    },
    {
      que: "How long does it take for Techbrain Networks to develop custom software?",
      ans: "The timeline for developing custom software depends on the complexity of the project and the specific requirements of the client. At Techbrain Networks, we work closely with our clients to understand their needs and provide an accurate timeline for the development process."
    },
    {
      que: "How much does it cost to develop custom software with Techbrain Networks?",
      ans: "The cost of developing custom software varies depending on the scope of the project, the complexity of the software, and the specific requirements of the client. At Techbrain Networks, we provide a detailed project estimate based on the specific needs of each client."
    },
    {
      que: "How does Techbrian Networks ensure data security?",
      ans: "We take data security very seriously and follow best practices to ensure the confidentiality, integrity, and availability of our clients' data. This includes implementing robust security protocols, conducting regular security audits, and using encryption technology."
    },
    {
      que: "What kind of digital marketing services does Techbrian Networks provide?",
      ans: "Techbrian Networks provides a range of digital marketing services, including SEO, PPC advertising, social media marketing, email marketing, and more."
    },
    {
      que: "what kind of hardware can Techbrian Networks develop?",
      ans:"Techbrian Networks can develop custom hardware for IoT solutions, such as sensors, controllers, and other IoT devices."
    },
     {
      que: "How does Techbrian Networks approach hardware development projects?",
      ans: "Techbrian Networks approaches hardware development projects in collaboration with their software development team to provide end-to-end IoT solutions that are tailored to their clients' needs and requirements. "
    },

    {
      que: "Does Techbrain Networks provide support and maintenance services for its software solutions?",
      ans: "Yes, Techbrain Networks provides ongoing support and maintenance services for all of our software solutions. We offer a range of support options, including phone and email support, as well as software updates and bug fixes."
    },
   
     {
      que: "What sets Techbrian Networks apart from other technology providers?",
      ans: "At Techbrian Networks, we are dedicated to delivering personalized and innovative solutions that help businesses succeed. Our team of experts has years of experience in the industry, and we are committed to staying ahead of the curve when it comes to technology trends and best practices. We pride ourselves on our customer-centric approach, and we work closely with our clients to create solutions that meet their specific needs and deliver real results."
    },
     {
      que: "How can I get started with Techbrian Networks?",
      ans: "To get started, simply contact us through our website, email or give us a call. Our team will be happy to discuss your specific needs and help you determine the best solution for your business. From there, we'll work with you every step of the way to create a customized plan and deliver results that help your business succeed."
    },
     


    
  ];
  const [currentFaq, setCurrentFaq] = React.useState(faqs);
  const [expand, setExpand] = React.useState(false);
  const handleExpand = () => {
    if (expand) {
      setCurrentFaq(faqs.slice(0, 7));
      setExpand(false);
    } else {
      setCurrentFaq(faqs);
      setExpand(true);
    }
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          "& .MuiPaper-root.MuiAccordion-root": {
            boxShadow: "0px 12px 21px rgb(0 0 0 / 5%)",
            borderTop: "none",
            borderRadius:'.4rem',
            p:1,
            bgcolor:'#111C3A',
            color: '#fff'
          },
          "& .MuiPaper-root.MuiAccordion-root.Mui-expanded": {
            boxShadow: "0px 12px 21px rgb(0 0 0 / 5%)",
            borderTop: "none",
            borderRadius:'.4rem',
            background: "linear-gradient(91.83deg,#FF512F 0%, #DD2476  100%)",
            color: "#fff",
            p:1
          },
          "& .MuiPaper-root.MuiAccordion-root:before": {
            height: 0,
            
          },
          "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
            color: "#fff", 
          },
          "& .MuiCollapse-root": {
            color: "#fff",
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            background: "transparent",
            color: "#fff",
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            background: "transparent",
            color: "#fff",
          },
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {currentFaq.map((i, k) => (
          <Accordion key={k}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel1a-content-${k}`}
              id={`panel1a-header-${k}`}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: { xs: "1rem", xl: "1.2rem" },
                  fontWeight: 400,
                }}
              >
                {i.que}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="h4"
                sx={{
                  fontSize: { xs: "1rem", xl: "1.2rem" },
                  fontWeight: 400,
                }}
              >
                {i.ans}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </>
  );
}
