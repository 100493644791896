import { Box, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from '@mui/material/styles';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
function IndustryCard({ i }) {
  const theme = useTheme();
  return (
    <Box>
    <Link href={i.id} color={"inherit"} underline="none">
      <Stack
        direction="column"  
        width={{ xs: "20rem", sm: "21rem", md: "18.5rem", lg: "20rem", xl: "25rem" }}
        height={{ xs: "28rem",sm: "30rem", md: "26.5rem", lg: "24rem", xl: "32rem" }}
        borderRadius={{ xs: ".3rem", md: "18px" }}
        bgcolor="#202020"
        sx={{
          borderRadius: "18px",
          background: `url(${i.img})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          p: [7],
          position: 'relative', 
          overflow: "hidden",
          border: "4px solid transparent", 
          transitionDuration:'500ms',
          [theme.breakpoints.up('lg')]: {
            "&:hover": {
              border: "4px solid #F44247",
              transform: "scale(1.1)",
            },
          },
        }}
      >
        <Box
                    className="img-overlay"
                    sx={{
                        height: ["100%"],
                        width: ["100%"],
                        left: "0",
                        top: "10%",
                        display: "flex",
                        justifyContent: "center",
                        background:
                            `linear-gradient(to bottom, rgba(0, 163, 255, 0),rgba(0, 163, 255, 0),rgba(0, 163, 255, 0), rgba(0, 0, 0, 1))`,
                        backgroundPosition: [
                            "center"
                        ],
                        backgroundRepeat: "no-repeat",
                        backgroundSize: [
                            "cover"
                        ],
                        zIndex: '18',
                        borderRadius: [".7rem .7rem 0 0", "1rem 1rem 0 0"],
                        position: "absolute",
                        transition: "0.4s ease",
                    }}
                />
        <Stack
          sx={{
            flex: 1,
          }}
        ></Stack>
        <Typography
          sx={{
            color:'#fff',
            fontWeight: 600,
            lineHeight: "normal",
            zIndex: '20',
            fontSize: [".9rem", ".9rem", ".8rem", ".9rem", "1.5rem"],
            textAlign: ["center"],
          }}
        >
          {i.title}
        </Typography>
      </Stack>
    </Link>
    </Box>
  );
}

export default IndustryCard;

