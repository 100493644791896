import {
  Box,
  Grid,
  Stack,
  Typography,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import Title from "../components/Title";
import Footer from "../components/Footer";
import { motion,  } from "framer-motion";
import Typed from "typed.js";
import { useRouter } from 'next/router'
import Navbar from "../components/Navbar";
import HeroEnquiryForm from "../components/HeroEnquiryForm";
import Faq from "../components/Faq";
import StickyWhatsapp from "../components/StickyWhatsapp";
import { industries, newIndustries } from "../utils/data";
import ClientsMarquee from "../components/ClientsMarquee";
import IndustryCard from "../components/IndustryCard";
import { GlobeDemo } from "../utils/GlobeDemo";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { FlipWordsDemo } from "../utils/FlipWordsDemo";
import Head from "next/head";


const cardAni = {
  offscreen: { y: 200, opacity: 0 },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 1,
      delayChildren: 0.3,
    },
  },
};

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 }
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
};
const flowAni = {
  offscreen: { x: -200, opacity: 0 },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: { type: "spring", bounce: 0.4, duration: 1.5 },
  },
};
const flowTxtAni = {
  offscreen: { scale: 0, opacity: 0 },
  onscreen: {
    opacity: 1,
    scale: [0, 1.2, 1],
    transition: { type: "spring", bounce: 0.4, duration: 1, delay: 0.5 },
  },
};
const textAni = {
  offscreen: { y: 20, opacity: 0 },
  onscreen: {
    y: 0,
    opacity: 1,
    // transition: {  duration: 1 },
  },
};
const fadeAni = {
  offscreen: { opacity: 0 },
  onscreen: {
    opacity: 1,
    // transition: {  duration: 1 },
  },
};


const Home = () => {
  const router = useRouter()
  const [isOpen, setIsOpen] = useState(false);
  const technologies = [
    "/tech/python.png",
    "/tech/tensor-flow.jpg",
    "/tech/keras.jpg",
    "/tech/o-dot.jpg",
    "/tech/scikit.png",
    "/tech/opencv.png",
    "/tech/hadoop.jpg",
    "/tech/spark.png",
    "/tech/flink.png",
    "/tech/kafka.jpg",
    "/tech/rabbit-mq.png",
    "/tech/redis.png",
    "/tech/django.png",
    "/tech/android.png",
    "/tech/kotlin.png",
    "/tech/swift.png",
    "/tech/flutter.png",
    "/tech/ionic.png",
    "/tech/rest.jpg",
    "/tech/html.png",
    "/tech/css.png",
    "/tech/js.png",
    "/tech/react.png",
    "/tech/nextjs.png",
    "/tech/vue.png",
    "/tech/redux.png",
    "/tech/node1.png",
    "/tech/graphql.png",
    "/tech/apollo.png",
    "/tech/express.png",
    "/tech/axios.png",
    "/tech/tailwind.png",
    "/tech/bootstrap.png",
    "/tech/mui.png",
    "/tech/material.png",
    "/tech/c++.png",
    "/tech/csharp.png",
    "/tech/git.png",
    "/tech/gitlab.png",
    "/tech/github.png",
    "/tech/triangle.png",
    "/tech/aws.png",
    "/tech/azure.png",
    "/tech/figma.svg",
    "/tech/xd.png",
    "/tech/maria-db.jpg",
    "/tech/mdb.jpg",
    "/tech/sql.jpg",
    "/tech/sql-server.jpg",
  ];


 const services = [
  {
    id: '/services/Software-Development',
    title: "Software Development",
    bgcolor: "#111C3A",
    color: "#D4126DEA",
    content:
      "We offer specialized software development services, including custom application design, system integration, and performance optimization. Our solutions are scalable, secure, and compliant with industry standards, backed by rigorous testing and validation.",
  },
  {
    id: '/services/Hardware-Engineering-Service',
    img: "/services/nft-creation.png",
    title: "Hardware",
    bgcolor: "#111C3A",
    color: "#F7CF77FF",
    content:
      "We offer advanced hardware development services, including precision PCB design, firmware development, sensor integration, and manufacturing. We specialize in IoT solutions and edge device integration for real-time data processing, ensuring compliance with industry standards through rigorous testing and validation.",
  },
  {
    id: '/services/Design-Service',
    img: "/services/data-analytics.png",
    title: "Product Design",
    bgcolor: "#111C3A",
    color: "#1BDE9C",
    content:
      "We streamline product design with advanced CAD, CAM, and 3D Printing technologies, offering precise modeling, efficient manufacturing, and rapid prototyping. Our solutions ensure seamless collaboration, performance optimization, and faster time-to-market, bringing innovative ideas to life cost-effectively.",
  },
  {
    id: '/services/Research-Development-Service',
    img: "/services/nft-creation.png",
    title: "Research & Development",
    bgcolor: "#111C3A",
    color: "#0088F0",
    content:
      "Our Research and Development (R&D) services focus on exploring new technologies, developing innovative solutions, and optimizing existing systems. We conduct feasibility studies, prototype development, and experimental testing to ensure high performance and compliance with industry standards.",
  },
];



  const el = React.useRef(null);
  const typed = React.useRef(null);

  React.useEffect(() => {
    const options = {
      strings: [
        "Artificial Intelligence",
        "Big Data",
        "Blockchain",
        "Cloud Computing",
        "Hardwares",
        "Internet of Things",
        "Industrial Design",
        "Quantum Computing",
      ],
      typeSpeed: 100,
      backSpeed: 100,
      loop: true,
    };

    typed.current = new Typed(el.current, options);

    return () => {
      typed.current.destroy();
    };
  }, []);
  return (
    <>
      <Head>
  <title>Techbrain Networks | Infinite Knowledge Meets Infinite Technologies</title>
  <meta name="description" content="Techbrain Networks is a global leader in software development, hardware engineering, and cutting-edge product design. We specialize in AI, IoT, Big Data, Blockchain, Cloud Computing, Quantum Computing, Spatial Computing, and Digital Marketing, delivering 480+ innovative products across 24 industries." />

  {/* <!-- Open Graph / Facebook --> */}
  <meta property="og:title" content="Techbrain Networks | Global Leader in Technology Innovation and R&D" />
  <meta property="og:description" content="Techbrain Networks is a global leader in software development, hardware engineering, and cutting-edge product design. We specialize in AI, IoT, Big Data, Blockchain, Cloud Computing, Quantum Computing, Spatial Computing, and Digital Marketing, delivering 480+ innovative products across 24 industries." />
  <meta property="og:url" content="https://www.techbrainnetworks.com" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://www.techbrainnetworks.com/logo-tbn.jpg" />
  <meta property="og:image:alt" content="Techbrain Networks Technology Overview" />
  <meta property="og:site_name" content="Techbrain Networks" />
  <meta property="og:locale" content="en_US" />

  {/* <!-- Twitter --> */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Techbrain Networks | Global Leader in Technology Innovation and R&D" />
  <meta name="twitter:description" content="Techbrain Networks is a global leader in software development, hardware engineering, and cutting-edge product design. Specializing in AI, IoT, Big Data, Blockchain, Cloud Computing, Quantum Computing, and more, we deliver 480+ products across 24 industries." />
  <meta name="twitter:url" content="https://www.techbrainnetworks.com" />
  <meta name="twitter:image" content="https://www.techbrainnetworks.com/logo-tbn.jpg" />
  <meta name="twitter:image:alt" content="Techbrain Networks Technology Overview" />

  {/* <!-- Additional Meta Tags --> */}
  <meta name="keywords" content="Techbrain Networks, software development, hardware engineering, product design, AI, IoT, Big Data, Blockchain, Cloud Computing, Quantum Computing, Spatial Computing, R&D services, technology innovation" />
  <meta name="author" content="Techbrain Networks" />
  <meta name="robots" content="index, follow" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <meta property="og:updated_time" content={new Date().toISOString()} />
  
  {/* Favicon and Fonts */}
  <link rel="icon" href="/favicon.png" />
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link href="https://fonts.googleapis.com/css2?family=YourFontFamily:wght@400;700&display=swap" rel="stylesheet" />
</Head>

    
      <Navbar color="#fff"  />
      <StickyWhatsapp link={"https://api.whatsapp.com/send?phone=918754906714"} />
     <Box sx={{width:'100vw',height:'100vh',position:'fixed',zIndex:1,bgcolor:'#080f25'}}>
     </Box>
      {/* section 1 - home */}
      <Box>
      <Box
        gap={{ xs: "1rem", sm: 0 }}
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: 'hidden',
          zIndex: 21,
          position: "relative",
          height: ["45rem", "100vh"],
        }}
      >
        <Stack
          alignItems="center"
          direction="column"
          justifyContent="center"
          width="100%"
        >
           <Typography
      sx={{
        fontSize: ["11px", "19px", "25px", "20px", "25px"],
        fontWeight: '500',
        color: 'white',
        letterSpacing:['0.2rem','0.5rem','0.5rem','0.5rem','0.5rem'],
        textAlign: 'center',
        position: "relative",
        textTransform:'uppercase',
        mt: [2, 2, 2, 2], // Add some margin-top to separate the two lines slightly
      }}
    >At TechBrain Networks</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>
            <Typography
              className="worksans"
              sx={{
                fontSize: ["25px", "50px", "60px", "75px", "80px"],
                textAlign: "center",
                lineHeight: 1.2,
                fontWeight: 600,
                px: [0.5],
                background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                backgroundClip: "text",
                textFillColor: "transparent",
                whiteSpace: 'nowrap',
              }}
            >
              We
            </Typography>
            <FlipWordsDemo />
          </Box>
          
          <Typography
      sx={{
        fontSize: ["15px", "30px", "30px", "30px", "40px"],
        fontWeight: '600',
        color: 'white',
        textAlign: 'center',
        position: "relative",
        // mt: [2, 2, 2, 2], // Add some margin-top to separate the two lines slightly
      }}
    >
      Where <span style={{ background: 'linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)',fontWeight:"600",WebkitBackgroundClip: 'text',WebkitTextFillColor: 'transparent',backgroundClip: 'text',textFillColor: 'transparent',}}>Infinite Knowledge</span> meets <span style={{ background: 'linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)',fontWeight:"600",WebkitBackgroundClip: 'text',WebkitTextFillColor: 'transparent',backgroundClip: 'text',textFillColor: 'transparent',}}>Infinite Technologies!
      </span>
    </Typography>
          <Box sx={{ width: 'fit', position: 'absolute', zIndex: ['-1', '-1', '-1', '-1'] }}>
            <GlobeDemo />
          </Box>
        </Stack>
      </Box>
    </Box>
      {/* Type animation */}
      <Box
        py={{ xs: 9 }}
        sx={{
          zIndex: 21,
          display: "flex",
          px: [2, 2, 3, 4],
          alignItems: "center",
          height: ["30rem", "40rem", "100vh"],
          justifyContent: "center",
          position: "relative",
          overflow: "hidden", color: '#fff'
        }}
      >
        {/* star */}
        <Stack
          component={motion.div}
          animate={{ x: -40, }}
          transition={{
            duration: 2, repeat: Infinity, repeatType: "reverse"
          }}
          sx={{
            width: [".9rem", "1.1rem", "1.4rem", "1.5rem", "2rem"],
            height: [".9rem", "1.1rem", "1.4rem", "1.5rem", "2rem"],
            position: "absolute",
            left: ["15%"],
            top: ["10%", "20%"],
          }}
        >
          <img width="100%" height="auto" alt="icon" src="/star1.png" />
        </Stack>
  
        {/* robo */}
        <Stack
    
          sx={{
            width: ["40rem", "40rem", "50rem", "50rem", "60rem"],
            height: ["40rem", "40rem", "50rem", "50rem", "60rem"],
            position: "absolute",
            right: ["-20rem","-20rem","-25rem","-25rem", "-32rem"],
          }}
        >
          <img width="auto" height="100%" alt="icon" src="/64f6cafbbe0d05a4a06b76e5_Detail Feature - Pattern.webp" />
        </Stack>
        {/* star 360 */}
        <Stack
          component={motion.div}
          animate={{ rotate: 360, }}
          transition={{
            duration: 10, repeat: Infinity, ease: "linear"
          }}
          sx={{
            width: ["1rem", "1rem", "1.5rem", "1.8rem", "2rem"],
            height: ["1rem", "1rem", "1.5rem", "1.8rem", "2rem"],
            position: "absolute",
            left: ["10%"],
            bottom: [0, "50%"],
          }}
        >
          <img alt="icon" src="/star4.png" width="100%" height="auto" />
        </Stack>
        {/* sqr 360 */}
        <Stack
          component={motion.div}
          animate={{ rotate: 360, }}
          transition={{
            duration: 7, repeat: Infinity, ease: "linear",
          }}
          sx={{
            width: ["1rem", "1rem", "1.5rem", "1.8rem", "2rem"],
            height: ["1rem", "1rem", "1.5rem", "1.8rem", "2rem"],
            position: "absolute",
            right: ["10%"],
            bottom: [0, "5%"],
          }}
        >
          <img alt="icon" src="/square2.png" width="100%" height="auto" />
        </Stack>
       
        <Stack
          sx={{
            display: ["none", "block"],
            width: { xs: ".7rem", xl: "1rem" },
            height: { xs: ".7rem", xl: "1rem" },
            position: "absolute",
            left: ["30%"],
            borderRadius: "100%",
            bgcolor: "#1BDE9C",
            bottom: ["10%"],
          }}
        />
        <Stack
          sx={{
            display: ["none", "block"],
            width: { xs: ".7rem", xl: "1rem" },
            height: { xs: ".7rem", xl: "1rem" },
            position: "absolute",
            right: ["20%"],
            borderRadius: "100%",
            bgcolor: "#f2c444",
            top: ["10%"],
          }}
        />

        <Typography
          width={{ xs: "100%", sm: "90%", md: "70%", lg: "85%", xl: "75%" }}
          variants={textAni}
          className="intro worksans"
          viewport={{ once: true, amount: 0.5 }}
          initial={"offscreen"}
          whileInView={"onscreen"}
          component={motion.p}
          transition={{ delay: 0.5 }}
          sx={{
            
            textAlign: "center",
            // color: "#474747",
            color: '#fff',
            fontSize: ["25px", "50px", "60px", "75px", "80px" ],
            lineHeight: ["35px", "55px", "65px", "75px","85px" ],
            fontWeight: [800],
            mb: { xs: 1 },
            zIndex: "10"
          }}
        >
          We focus on building <br />
          cutting-edge <br />
          <Typography
            className="worksans typed"
            sx={{
              fontSize: ["25px", "50px", "60px", "75px", "80px"],
              textAlign: "center",
              // color: "#474747",
              lineHeight: ["35px", "55px", "65px", "75px","85px"],
              display: "inline",
              fontWeight: [800],
              mb: { xs: 1 },
            }}
            ref={el}
          />{" "}
          <br /> based Software Products that suit various industries.
        </Typography>
      </Box>
      {/* Industries  */}
      <Box
        sx={{
          px: [2, 4, 3, 4, 8],
          display: "flex",
          zIndex: 21,
          alignItems: "center",
          py: { xs: "1rem", xl: "3rem" },
          flexDirection: "column",
          gap: ["1rem", "1rem", "2rem"],
          mb: [0, 2, 6],
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Title>Industries</Title>
        <Typography
          width={{ xs: "100%", sm: "70%", md: "70%", lg: "70%", xl: "75%" }}
          variants={textAni}
          className="title-txt worksans"
          initial={"offscreen"}
          whileInView={"onscreen"}
          component={motion.p}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.5 }}
          sx={{
            color: "#fff",
            fontWeight: 300,
            lineHeight: "normal",

            fontSize: [".9rem", ".9rem", "1.2rem", "1.3rem", "2rem"],
            textAlign: ["center"],
            mb: { xs: 1 },
          }}
        >
         At our company, we strive to offer you the best technology possible to ensure an unparalleled user experience. we provide top-tier technology across 24 industries, delivering 480 innovative products.
        </Typography>
        <style>
        {`
          .swiper {
            width: 100%;
            height: 100%;
          }

          .swiper-slide {
            text-align: center;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .swiper {
            margin-left: auto;
            margin-right: auto;
          }
      .swiper-button-next,
.swiper-button-prev {
  background-color: #f5f5f7;
  border-radius: 100%;
  padding: 2rem;
  height: 4rem;
  width: 4rem;
  color: #F44247;
  display: flex;
  align-items: center;
  justify-content: center;
  // border:3px solid #F44247;
}

.swiper-button-next:after{
 font-size: 1.5rem;
  font-weight:900;
  text-align: center;
    margin-left: 6.5px;
     text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
       -webkit-text-stroke: 3px #F44247;
    text-stroke: 3px #F44247;
}
.swiper-button-prev:after {
  font-size: 1.5rem;
  font-weight:900;
  text-align: center;
    margin-left: -3px;
     text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
       -webkit-text-stroke: 3px #F44247;
    text-stroke: 3px #F44247;
}
@media (max-width: 800px) {
  .swiper-button-next,
  .swiper-button-prev {
    padding: 1rem; /* Adjust padding for smaller screens */
    height: 2rem; /* Adjust height for smaller screens */
    width: 2rem; /* Adjust width for smaller screens */
  }

  .swiper-button-next:after{
   font-size: 1rem;
    margin-left: 0.3rem; /* Adjust margin for smaller screens */
    -webkit-text-stroke: 0.1125rem #F44247; /* Adjust text stroke for smaller screens */
    text-stroke: 0.1125rem #F44247; /* Adjust text stroke for smaller screens */
  }
  .swiper-button-prev:after {
   font-size: 1rem;
    margin-left: -0.3rem; /* Adjust margin for smaller screens */
    -webkit-text-stroke: 0.1125rem #F44247; /* Adjust text stroke for smaller screens */
    text-stroke: 0.1125rem #F44247; /* Adjust text stroke for smaller screens */
  }
}
          `}
      </style>
      <Box sx={{ width: '100%', height:["29rem","32rem","25rem","29rem","36rem"],}}>
        <Swiper
          slidesPerView={1}
          navigation={true}
          modules={[ Navigation]}
          className="mySwiper"
          breakpoints={{
        
            700: {
              slidesPerView: 2, 
            },
            1024: {
              slidesPerView: 3, 
            },
            1200: {
              slidesPerView: 4, 
            },
            1450: {
              slidesPerView: 3, 
            },
            1700: {
              slidesPerView: 4, 
            },
            
          }}
        >
          {newIndustries.slice(0, 6).map((i, index) => (
            <SwiperSlide key={index}>
              <IndustryCard i={i} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      <Box sx={{ width: '100%', height:["29rem","32rem","25rem","29rem","36rem"],}}>
        <Swiper
          slidesPerView={1}
          navigation={true}
          modules={[ Navigation]}
          className="mySwiper"
          breakpoints={{
          
            700: {
              slidesPerView: 2, 
            },
            1024: {
              slidesPerView: 3, 
            },
            1200: {
              slidesPerView: 4, 
            },
            1450: {
              slidesPerView: 3, 
            },
            1700: {
              slidesPerView: 4, 
            },
          }}
        >
          {newIndustries.slice(6, 12).map((i, index) => (
            <SwiperSlide key={index}>
              <IndustryCard i={i} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box sx={{ width: '100%',  height:["29rem","32rem","25rem","29rem","36rem"], }}>
        <Swiper
          slidesPerView={1}
          navigation={true}
          modules={[ Navigation]}
          className="mySwiper"
          breakpoints={{
       
            700: {
              slidesPerView: 2, 
            },
            1024: {
              slidesPerView: 3, 
            },
            1200: {
              slidesPerView: 4, 
            },
            1450: {
              slidesPerView: 3, 
            },    1700: {
              slidesPerView: 4, 
            },
          }}
        >
          {newIndustries.slice(12, 18).map((i, index) => (
            <SwiperSlide key={index}>
              <IndustryCard i={i} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box sx={{ width: '100%', height:["29rem","32rem","25rem","29rem","36rem"], }}>
        <Swiper
          slidesPerView={1}
          navigation={true}
          modules={[ Navigation]}
          className="mySwiper"
          breakpoints={{
         
            700: {
              slidesPerView: 2, 
            },
            1024: {
              slidesPerView: 3, 
            },
            1200: {
              slidesPerView: 4, 
            },
            1450: {
              slidesPerView: 3, 
            },
            1700: {
              slidesPerView: 4, 
            },
          }}
        >
          {newIndustries.slice(18, 24).map((i, index) => (
            <SwiperSlide key={index}>
              <IndustryCard i={i} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      </Box>

      {/* our services */}
      <Box
        sx={{
          px: [2, 2, 3, 4, 7],
          display: "flex",
          alignItems: "center",
          py: { xs: "1rem", xl: "4rem" },
          flexDirection: "column",
          gap: ["1rem", "1.2rem"],
          mb: [0, 2, 6],
          overflow: "hidden",
          position: "relative",
          // bgcolor:'#181818'
          zIndex: 21,
        }}
      >   
        {/* star 360 */}
        <Stack
          component={motion.div}
          animate={{ rotate: 360, }}
          transition={{
            duration: 10, repeat: Infinity, ease: "linear"
          }}
          sx={{
            display: ["none", "block"],
            width: ["1rem", "1rem", "1.5rem", "1.8rem", "2rem"],
            height: ["1rem", "1rem", "1.5rem", "1.8rem", "2rem"],
            position: "absolute",
            left: ["3%"],
            top: ["13%"],
          }}
        >
          <img alt="icon" src="/star4.png" width="100%" height="auto" />
        </Stack>
        <Stack
          sx={{
            display: ["none", "block"],
            height: ["5rem", "6rem", "7rem", "8rem", "10rem"],
            position: "absolute",
            right: ["0"],
            top: ["60%", "70%"],
          }}
        >
          <img alt="icon" src="/dotR-p.png" width="auto" height="100%" />
        </Stack>
        <Stack
          sx={{
            width: ["40rem", "40rem", "50rem", "50rem", "60rem"],
            height: ["40rem", "40rem", "50rem", "50rem", "60rem"],
            position: "absolute",
            left: ["-20rem","-20rem","-25rem","-25rem", "-32rem"],
            top: ["30%", "40%"],
          }}
        >
          <img width="auto" height="100%" alt="icon" src="/64f6cafbbe0d05a4a06b76e5_Detail Feature - Pattern.webp" />
        </Stack>
        {/* sqr 360 */}
        <Stack
          component={motion.div}
          animate={{ rotate: 360, }}
          transition={{
            duration: 7, repeat: Infinity, ease: "linear",
          }}
          sx={{
            width: ["2rem", "3rem", "3.5rem", "4rem", "5rem"],
            height: ["2rem", "3rem", "3.5rem", "4rem", "5rem"],
            position: "absolute",
            left: ["-10rem", "-1.5rem", "-1.7rem", "-2rem", "-2.5rem"],
            bottom: ["5%"],
          }}
        >
          <img alt="icon" src="/square2.png" width="100%" height="auto" />
        </Stack>
        {/* <BackgroundBeamsDemo/> */}
        <Title>Services</Title>
        <Typography
        width={{ xs: "100%", sm: "70%", md: "70%", lg: "85%", xl: "75%" }}
        variants={textAni}
        className="title-txt worksans"
        viewport={{ once: true, amount: 0.5 }}
        initial={"offscreen"}
        whileInView={"onscreen"}
        component={motion.p}
        transition={{ delay: 0.5 }}
        sx={{
          fontSize: [".9rem", ".9rem", "1.2rem", "1.3rem", "2rem"],
          textAlign: "center",
          color: "#fff",
          fontWeight: 300,
          lineHeight: "normal",
          mb: { xs: 1, sm: 2 },
        }}
      >
        {"Whether you're looking for a prototype or a complete product, we have the perfect solution for you. And we customize it to suit your budget and specifications."}
      </Typography>
      <Stack
        direction="row"
        flexWrap="wrap"
        mt={{ xl: 2 }}
        gap={{ xs: 6, sm: 6, md: 4, xl: 10 }}
        px={{ xs: 1, md: 0, xl: 12 }}
        width="100%"
        justifyContent="center"
      >
        {services.map((item, index) => (
          <Box
            key={index}
            component={motion.div}
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true, amount: .4 }}
            transition={{ staggerChildren: 0.5, delay: 0.3 }}
            variants={cardAni}
            whileHover={{
              scale: 1.05,
              transition: { type: "spring", bounce: 0.4, stiffness: 300 },
            }}
            width={{ xs: "100%", sm: "45%", md: "35%", lg: "30%", xl: "45%" }}
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: { xs: "24px", xl: "3.5rem" },
              zIndex: "100",
              cursor: 'pointer',
              ":hover": {
                backgroundColor: `${item.color}`,
                color: "#fff !important",
                boxShadow: 4,
                border: "none",
              },
              ":hover > a > *": {
                color: "#fff !important",
              },
              backgroundColor: `${item.bgcolor}`,
              position: "relative",
              transition: "box-shadow 0.3s ease-in-out",
            }}
          >
            <Link color={'inherit'} href={`${item.id}`} underline="none" width="100%"
              gap={{ xs: 1, lg: 2 }}
              py={{ xs: "2rem", xl: "4rem" }}
              px={{ xs: "2.1rem", xl: "4rem" }}
              sx={{
                display: "flex",
                height: '100%',
                flexDirection: "column",
              }}
            >
              <Typography
                variants={textAni}
                component={motion.p}
                className="worksans"
                sx={{
                  fontSize: ["1.8rem", "1.8rem", "1.8rem", "2rem", "4rem"],
                  color: `${item.color}`,
                  textAlign: ["center", "center"],
                  lineHeight: { xs: "35px", xl: "4rem" },
                  fontWeight: [700, 800],
                }}
              >
                {item.title}
              </Typography>
              <Typography
                className="worksans"
                variants={textAni}
                component={motion.p}
                sx={{
                  fontWeight: 400,
                  lineHeight: { xs: "25px", xl: "2.9rem" },
                  color: "#fff",
                  fontSize: [".8rem", ".8rem", ".9rem", "1rem", "1.4rem"],  // Try slightly smaller font sizes
                  textAlign: 'justify',
                  letterSpacing: '-0.5px',
                  wordSpacing: '-0.5px',  // Try reducing less drastically
                  hyphens: 'auto'
                }}
                
                
              >
                {item.content}
              </Typography>
            </Link>
          </Box>
        ))}
      </Stack>


      </Box>

      {/* how we work */}
      <Box
        sx={{
          px: [2, 2, 3, 4, 14],
          display: "flex",
          alignItems: "center",
          py: "1rem",
          flexDirection: "column",
          gap: ["1rem", "1rem", "1.6rem", "2.1rem"],
          mb: [0, 2, 6],
          position: "relative",
          overflow: "hidden", zIndex: 21,
          // bgcolor:'#181818'
        }}
      > {/* square */}
        <Stack
          component={motion.div}
          animate={{ y: 20, }}
          transition={{
            duration: 2, repeat: Infinity, repeatType: "reverse"
          }}
          sx={{
            display: ["block", "none"],
            height: ["1rem", "1rem", "1.5rem", "1.8rem", "2rem"],
            position: "absolute",
            right: ["10%"],
            top: ["80%"],
          }}
        >
          <motion.img alt="icon"
            animate={{ rotate: 360, }}
            transition={{
              duration: 3, repeat: Infinity, ease: "linear",
            }} src="/square2.png" style={{ height: "100%", }} />
        </Stack>
        {/* plus */}
        <Stack
          component={motion.div}
          animate={{ y: 20, }}
          transition={{
            duration: 2, repeat: Infinity, repeatType: "reverse"
          }}
          sx={{
            height: ["1rem", "1rem", "1.5rem", "1.8rem", "2rem"],
            position: "absolute",
            right: ["10%"],
            top: ["20%"],
          }}
        >
          <motion.img alt="icon"
            animate={{ rotate: 360, }}
            transition={{
              duration: 3, repeat: Infinity, ease: "linear",
            }} src="/plus-p.png" style={{ height: "100%", }} />
        </Stack>
        {/* star */}
        <Stack
          component={motion.div}
          animate={{ x: -20, }}
          transition={{
            duration: 2, repeat: Infinity, repeatType: "reverse"
          }}
          sx={{
            display: ["block", "none"],
            height: ["1rem", "1rem", "1.5rem", "2rem", "2rem"],
            position: "absolute",
            right: ["3%"],
            top: ["50%"],
          }}
        >
          <img alt="icon" src="/star3d-b.png" width="auto" height="100%" />
        </Stack>
        
        {/* star */}
        <Stack
          component={motion.div}
          animate={{ x: 20, }}
          transition={{
            duration: 2, repeat: Infinity, repeatType: "reverse"
          }}
          sx={{
            display: ["block", "none"],
            height: ["1rem", "1rem", "1.5rem", "2rem", "2rem"],
            position: "absolute",
            left: ["3%"],
            top: ["60%"],
          }}
        >
          <img alt="icon" src="/spark-g.png" width="auto" height="100%" />
        </Stack>
        {/* star */}
        <Stack
          component={motion.div}
          animate={{ y: 20, }}
          transition={{
            duration: 2, repeat: Infinity, repeatType: "reverse"
          }}
          sx={{
            height: ["1rem", "1rem", "1.5rem", "2rem", "2rem"],
            position: "absolute",
            left: ["3%"],
            top: ["40%"],
          }}
        >
          <img alt="icon" src="/star3d.png" width="auto" height="100%" />
        </Stack>
        {/* rotate */}
        <Stack
          component={motion.div}
          animate={{ rotate: 360, }}
          transition={{
            duration: 10, repeat: Infinity, ease: "linear"
          }}
          sx={{
            display: ["block", "none"],
            height: ["1rem", "1rem", "1.5rem", "2rem", "2rem"],
            position: "absolute",
            left: ["3%"],
            bottom: ["0"],
          }}
        >
          <img alt="icon" src="/plus-b.png" width="auto" height="100%" />
        </Stack>
        <Title>How We Work</Title>
        <Typography
          width={{ xs: "100%", sm: "70%", md: "70%", lg: "70%", xl: "75%" }}
          variants={textAni}
          className="title-txt worksans"
          viewport={{ once: true, amount: 0.5 }}
          initial={"offscreen"}
          whileInView={"onscreen"}
          component={motion.p}
          transition={{ delay: 0.5 }}
          sx={{
            color: "#fff",
            fontWeight: 300,
            lineHeight: "normal",
            fontSize: [".9rem", ".9rem", "1.2rem", "1.3rem", "2rem"],
            textAlign: ["center"],
            mb: { xs: 1 },
          }}
        >
          Using Agile methodology we believe in breaking our projects down into
          small, achievable tasks so that we can deliver the best value for our
          clients within the shortest possible time frame.
        </Typography>
        <Grid
          container
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: true, amount: 1 }}
          component={motion.div}
          sx={{
            display: ["none", "flex"],
            px: ["1rem", "5rem", "5rem", "5rem", 0],
            mt: [0, 1.5, 0, 0, 2]
          }}
        >
          <Grid item xs={2}></Grid>
          <Grid item xs={2} alignItems="center">
            <Typography
              variants={flowTxtAni}
              component={motion.p}
              sx={{
                fontSize: [".7rem", ".9rem", ".8rem", "1rem", "1.4rem"],
                textAlign: "center",
                fontWeight: [500, 600],
                background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                backgroundClip: "text",
                textFillColor: "transparent",
              }}
            >
              Feasibility Study
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2} alignItems="center">
            <Typography
              variants={flowTxtAni}
              component={motion.p}
              sx={{
                fontSize: [".7rem", ".9rem", ".8rem", "1rem", "1.4rem"],
                textAlign: "center",
                fontWeight: [500, 600],
                background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                backgroundClip: "text",
                textFillColor: "transparent",
              }}
            >
              Project Development
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2} alignItems="center">
            <Typography
              variants={flowTxtAni}
              component={motion.p}
              sx={{
                fontSize: [".7rem", ".9rem", ".8rem", "1rem", "1.4rem"],
                textAlign: "center",
                fontWeight: [500, 600],
                background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                  backgroundClip: "text",
                  textFillColor: "transparent",
              }}
            >
              Product Delivery
            </Typography>
          </Grid>
          <Grid item xs={12} variants={flowAni} component={motion.div}>
            <img
              alt="proccess" src="/flow-md.png"
              width="100%" height="auto"
            />
          </Grid>
          <Grid item xs={2} alignItems="center">
            <Typography
              variants={flowTxtAni}
              component={motion.p}
              sx={{
                fontSize: [".7rem", ".9rem", ".8rem", "1rem", "1.4rem"],
                textAlign: "center",
                fontWeight: [500, 600],
                background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                backgroundClip: "text",
                textFillColor: "transparent",
              }}
            >
              Requirements Gathering
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2} alignItems="center">
            <Typography
              variants={flowTxtAni}
              component={motion.p}
              sx={{
                fontSize: [".7rem", ".9rem", ".8rem", "1rem", "1.4rem"],
                textAlign: "center",
                fontWeight: [500, 600],
                background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                backgroundClip: "text",
                textFillColor: "transparent",
              }}
            >
              Design Approval
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2} alignItems="center">
            <Typography
              variants={flowTxtAni}
              component={motion.p}
              sx={{
                fontSize: [".7rem", ".9rem", ".8rem", "1rem", "1.4rem"],
                textAlign: "center",
                fontWeight: [500, 600],
                background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                backgroundClip: "text",
                textFillColor: "transparent",
              }}
            >
              Product Testing
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>

        {/* mobile view */}
        <Grid
          container
          sx={{
            display: ["flex", "none"],
            px: ["1rem", "5rem"],
            mt: [0, 1.5, 0]
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "40rem",
              position: "relative",
            }}
          >
            <Grid
              container
              sx={{
                position: "absolute",
                height: "100%",
                top: "1rem",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  sx={{
                    fontSize: [".7rem", ".8rem", ".8rem", "1rem", "2rem"],
                    textAlign: "center",
                    width: "40%",
                    fontWeight: [500, 600],
                    background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                  }}
                >
                  Requirements Gathering
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  sx={{
                    fontSize: [".7rem", ".8rem", ".8rem", "1rem", "2rem"],
                    textAlign: "center",
                    width: "40%",
                    fontWeight: [500, 600],
                    background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                  }}
                >
                  Feasibility Study
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  sx={{
                    fontSize: [".7rem", ".8rem", ".8rem", "1rem", "2rem"],
                    textAlign: "center",
                    width: "40%",
                    fontWeight: [500, 600],
                    background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                  }}
                >
                  Design Approval
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  sx={{
                    fontSize: [".7rem", ".8rem", ".8rem", "1rem", "2rem"],
                    textAlign: "center",
                    width: "40%",
                    fontWeight: [500, 600],
                    background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                  }}
                >
                  Project Development
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  sx={{
                    fontSize: [".7rem", ".8rem", ".8rem", "1rem", "2rem"],
                    textAlign: "center",
                    width: "40%",
                    fontWeight: [500, 600],
                    background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                  }}
                >
                  Product Testing
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  sx={{
                    fontSize: [".7rem", ".8rem", ".8rem", "1rem", "2rem"],
                    textAlign: "center",
                    width: "40%",
                    fontWeight: [500, 600],
                    background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                  }}
                >
                  Product Delivery
                </Typography>
              </Grid>
            </Grid>
            <img
              alt="proccess" src="/flow-xs.png"
              width="auto" height="100%"
            />
          </Grid>
        </Grid>
      </Box>
      {/* tech we use */}
      <Box
        sx={{
          px: [2, 4, 3, 4, 8],
          display: "flex",
          alignItems: "center",
          py: { xs: "1rem", xl: "3rem" },
          flexDirection: "column",
          gap: ["1rem", "1rem", "2rem"],
          mb: [0, 2, 6],
          position: "relative",
          zIndex: 21,
          overflow: "hidden"
        }}
      >
        {/* sqr 360 */}
        <Stack
          component={motion.div}
          animate={{ rotate: 360, }}
          transition={{
            duration: 7, repeat: Infinity, ease: "linear",
          }}
          sx={{
            width: ["2rem", "3rem", "3.5rem", "4rem", "5rem"],
            height: ["2rem", "3rem", "3.5rem", "4rem", "5rem"],
            position: "absolute",
            right: ["-1rem", "-1.5rem", "-1.7rem", "-2rem", "-2.5rem"],
            top: ["5%"],
          }}
        >
          <img alt="icon" src="/square.png" width="100%" height="auto" />
        </Stack>
        <Stack
          sx={{
            height: ["5rem", "6rem", "7rem", "8rem", "10rem"],
            position: "absolute",
            left: ["0"],
            bottom: ["60%", "70%"],
          }}
        >
          <img alt="icon" src="/dotL-o.png" width="auto" height="100%" />
        </Stack>
        <Stack
          sx={{
            height: ["5rem", "6rem", "7rem", "8rem", "10rem"],
            position: "absolute",
            right: ["0"],
            top: ["70%"],
          }}
        >
          <img alt="icon" src="/dotR-p.png" width="auto" height="100%" />
        </Stack>
        <Stack
          sx={{
            width: ["40rem", "40rem", "50rem", "50rem", "60rem"],
            height: ["40rem", "40rem", "50rem", "50rem", "60rem"],
            position: "absolute",
            top:'0',
          }}
        >
          <img width="auto" height="100%" alt="icon" src="/662e52e7beab55128dbadb7a_Gradient Effect.webp" />
        </Stack>
        <Title>Technologies</Title>
        <Typography
          width={{ xs: "100%", sm: "70%", md: "70%", lg: "70%", xl: "75%" }}
          variants={textAni}
          className="title-txt worksans"
          initial={"offscreen"}
          whileInView={"onscreen"}
          component={motion.p}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.5 }}
          sx={{
            color: "#fff",
            fontWeight: 300,
            lineHeight: "normal",

            fontSize: [".9rem", ".9rem", "1.2rem", "1.3rem", "2rem"],
            textAlign: ["center"],
            mb: { xs: 1 },
          }}
        >
          We strive to offer you the best technology possible in order to give
          you the best user experience. With the latest technologies, we build
          the finest product possible and to make it available to everyone.
        </Typography>
        <Stack
          direction="row"
          zIndex="20"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          gap={{ xs: ".5rem", xl: "1.2rem" }}
          px={{ xs: 1, md: 3, lg: 10 }}
          component={motion.div}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ staggerChildren: 0.1, duration: 0.5 }}
        >
          {technologies.map((icon, index) => (
            <Stack
              component={motion.div}
              variants={fadeAni}
              key={index}
              direction="row"
              alignItems="center"
              justifyContent="center"
              width={{ xs: "3rem", md: "3.5rem", lg: "4rem", xl: "5rem" }}
              height={{ xs: "3rem", md: "3.5rem", lg: "4rem", xl: "5rem" }}
              boxShadow={{ xs: 1, xl: 3 }}
              borderRadius={{ xs: ".3rem", md: ".5rem" }}
              bgcolor="#fff"
              p=".5rem"
            >
              <img
                alt="tech" src={icon}
                width="100%" height="auto"
              />
            </Stack>
          ))}
        </Stack>
      </Box>

      {/* works */}
      <Box
        // bgcolor="#181818"
        py={{ xs: 3, md: 5, }}
        sx={{
          zIndex: 21,
          display: "flex",
          width: "100%",
          alignItems: "center",
          height: ["auto", "auto",],
          flexDirection: ["column", "column",],
          justifyContent: "space-between",
          gap: [3, 5, 3],
          position: "relative",
        }}
      >
        {/* star */}
        <Stack
          component={motion.div}
          animate={{ x: -40, }}
          transition={{
            duration: 2, repeat: Infinity, repeatType: "reverse"
          }}
          sx={{
            width: ["1.4rem", "2.1rem", "2.7rem", "3rem", "3.6rem"],
            position: "absolute",
            right: ["9%", "10%"],
            top: ["3%", "2%"],
          }}
        >
          <img alt="icon" src="/spark2-y.png" width="100%" height="auto" />
        </Stack>
        {/* str 360 */}
        <Stack
          component={motion.div}
          animate={{ rotate: 360, }}
          transition={{
            duration: 7, repeat: Infinity, ease: "linear",
          }}
          sx={{
            width: ["1rem", "2rem", "2.5rem", "3rem", "3.6rem"],
            position: "absolute",
            left: ["2rem", "10%"],
            top: ["10%", "5%"],
          }}
        >
          <img alt="icon" src="/star3d.png" width="100%" height="auto" />
        </Stack>
        <Title>Our Works</Title>
        <Box sx={{
          width: "100%",
          position: "relative",
          overflow: "hidden",
          height: ["130vw", "85vw", "85vw", "85vw", "85vw"],
        }}>

          {/* quickF */}
          <Box
            component={motion.div}
            animate={{ y: 15, }}
            transition={{
              duration: 2, repeat: Infinity, repeatType: "reverse"
            }}
            sx={{
              width: ["58vw", '48vw'],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: ".5rem",
              boxShadow: 6,
              position: "absolute",
              bottom: ["35px", "0"],
              left: ["-30%", "-20%"],
              zIndex: "40"
            }}>
            <img alt="quick-fire" width="100%" height="auto" src="/projects/quickF.png" style={{ boxShadow: 2, borderRadius: ".5rem", }} />
          </Box>
          {/* hm */}
          <Box
            component={motion.div}
            animate={{ y: -40, }}
            transition={{
              duration: 2, repeat: Infinity, repeatType: "reverse"
            }}
            sx={{
              width: ['50vw', '45vw'],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: ".5rem",
              boxShadow: 6,
              position: "absolute",
              top: ["50%"],
              left: ["-10%"],
              zIndex: "30"
            }}>
            <img alt="home-needs" src="/projects/homeneeds3.webp" width="100%" height="auto" style={{ boxShadow: 2, borderRadius: ".5rem", }} />
          </Box>
          {/* tafe */}
          <Box
            component={motion.div}
            animate={{ y: -50, }}
            transition={{
              duration: 3, repeat: Infinity, repeatType: "reverse"
            }}
            sx={{
              width: ['48vw', '35vw'],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: ".5rem",
              boxShadow: 6,
              position: "absolute",
              top: ["10%"],
              right: ["-20px"]
            }}>
            <img alt="project" src="/projects/tafe.jpg" width="100%" height="auto" style={{ boxShadow: 2, borderRadius: ".5rem", }} />
          </Box>
          {/* hm f */}
          <Box
            component={motion.div}
            animate={{ y: 30, }}
            transition={{
              duration: 2, repeat: Infinity, repeatType: "reverse"
            }}
            sx={{
              width: ['50vw', '45vw'],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: ".5rem",
              boxShadow: 6,
              position: "absolute",
              top: ["40%"],
              right: ["20px"]
            }}>
            <img alt="home-needs" src="/projects/homeneeds2.webp" width="100%" height="auto" style={{ boxShadow: 2, borderRadius: ".5rem", }} />
          </Box>
          {/* eco f */}
          <Box
            component={motion.div}
            animate={{ y: -30, }}
            transition={{
              duration: 6, repeat: Infinity, repeatType: "reverse"
            }}
            sx={{
              width: ['20vw', '15vw'],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: ".5rem",
              boxShadow: 5,
              position: "absolute",
              top: ["26%"],
              left: ["6%"],
              zIndex: "29"
            }}>
            <img alt="eco-rich" src="/projects/eco-f.webp" width="100%" height="auto" style={{ boxShadow: 2, borderRadius: ".5rem", }} />
          </Box>
          {/* arm */}
          <Box
            component={motion.div}
            animate={{ y: -40, }}
            transition={{
              duration: 6, repeat: Infinity, repeatType: "reverse"
            }}
            sx={{
              width: ['20vw', '15vw'],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: ".5rem",
              boxShadow: 6,
              position: "absolute",
              bottom: ["-10%", "-15%"],
              left: ["33%"],
              zIndex: "20"
            }}>
            <img alt="health-care" src="/projects/armor-1.webp" width="100%" height="auto" style={{ boxShadow: 2, borderRadius: ".5rem", }} />
          </Box>
          {/* dashboard */}
          <Box
            component={motion.div}
            animate={{ y: -30, }}
            transition={{
              duration: 3, repeat: Infinity, repeatType: "reverse"
            }}
            sx={{
              width: ['55vw', '50vw'],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: ".5rem",
              boxShadow: 6,
              position: "absolute",
              top: ["10%"],
              left: ["-6%", "-1%"],
              zIndex: "20",
            }}>
            <img alt="dashboard" src="/projects/admin-dashboard.png" width="100%" height="auto" style={{ boxShadow: 2, borderRadius: ".5rem", }} />
          </Box>
          {/* eco land */}
          <Box
            component={motion.div}
            animate={{ y: -30, }}
            transition={{
              duration: 3, repeat: Infinity, repeatType: "reverse"
            }}
            sx={{
              width: ['40vw', '35vw'],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: ".5rem",
              boxShadow: 6,
              position: "absolute",
              top: ["30%"],
              left: ["29%", "33%"],
              zIndex: "50",
            }}>
            <img alt="sarielkent" src="/projects/sarielkent1.webp" width="100%" height="auto" style={{ boxShadow: 2, borderRadius: ".5rem", }} />
          </Box>
        </Box>
      </Box>

      {/* clients */}
      <Box
        py={{ xs: 2.5, sm: 5, md: 6, lg: 8 }}
        sx={{
          px: [2, 2, 3, 4, 12],
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          gap: ["3rem", "3rem", "4rem", "4rem", "6rem"],
          // bgcolor: "#181818",
          zIndex: 21,
        }}
      >
        <Stack
          sx={{
            display: ["block", "none"],
            height: ["5rem", "6rem", "7rem", "8rem", "10rem"],
            position: "absolute",
            left: ["0"],
            bottom: ["70%"],
          }}
        >
          <img alt="icon" src="/dotL-o.png" width="auto" height="100%" />
        </Stack>
        <Stack
          component={motion.div}
          animate={{ x: 20, }}
          transition={{
            duration: 2, repeat: Infinity, repeatType: "reverse"
          }}
          sx={{
            height: ["2rem", "2rem", "3rem", "4rem", "5.5rem"],
            position: "absolute",
            right: ["10%"],
            top: ["2%"],
          }}
        >
          <img alt="icon" src="/star3d-b.png" width="auto" height="100%" />
        </Stack>
        <Stack
          direction="column"
          justifyContent="center"
          gap={{ xs: 2.3, sm: 2.3, lg: 2, xl: 3 }}
          alignItems="center"
          width="100%"
        >
          <Title>Clients</Title>
          <ClientsMarquee />
        </Stack>

      </Box>

      {/* form */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: ["column"],
          alignItems: "center",
          justifyContent: "center",
          position:'relative',
          zIndex: 21,
          gap: ["1.5rem", "3rem", "2rem", "3rem"],
          py: ["4rem", "4.2rem", "2.8rem", "4rem", "8rem"],
          px: ["1rem", "1.2rem", "1.8rem", "4rem", "5rem"],
          color: "#000",
        }}
      >
      
        <HeroEnquiryForm heroPage />
      </Box>
      {/* faq */}
      <Box
        py={{ xs: 2.5, sm: 5, md: 6, lg: 8 }}
        sx={{
          px: [2, 2, 3, 4, 12],
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          gap: ["3rem", "3rem", "4rem", "4rem", "6rem"],
          // bgcolor: "#181818",
          zIndex: 21,
        }}
      >
        <Title>Frequently Asked Question</Title>
        <Faq />
      </Box>
      <Box
        py={{ xs: 2.5, sm: 5, md: 6, lg: 8 }}
        sx={{
          px: [2, 2, 3, 4, 4],
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          gap: ["3rem", "3rem", "4rem", "4rem", "6rem"],
          // bgcolor: "#181818",
          zIndex: 21,
        }}
      >
<Footer />
</Box>

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            'name': 'Techbrain Networks',
            'logo': 'https://www.techbrainnetworks.com/logo.jpg',
            'url': 'https://www.techbrainnetworks.com',
            'sameAs': [
              'https://www.facebook.com/techbrainnetworks',
              'https://twitter.com/techbrain',
              'https://www.linkedin.com/company/techbrain-networks'
            ]
          })
        }}
      />
      {/* <ThreeDScene/> */}
  <Box
    sx={{
      width: '100vw',
      height: '100vh', 
      position: 'fixed',
      // zIndex: '10',
      backgroundColor: "#181818D7",
      inset: 0,
    }}
  ></Box>
    </>
  );
};

export default Home;

