import React from "react";
import { Box, Link } from "@mui/material";
import { keyframes } from "@emotion/react";

const scrollX = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

function ClientsMarquee() {
  const clients = [
    { icon: "/clients/ecorich.png", link: null },
    { icon: "/clients/bejan-singh-eye-hospital1.webp", link: "https://bejan-singh-eye-hospital1.vercel.app/" },
    {
      icon: "/clients/your-booking-expert.webp",
      link: "https://yourbookingexpert.co.uk/",
    },
    { icon: "/clients/sarielkent.png", link: "https://www.sarielkent.co.uk/" },
    { icon: "/clients/red-panda.png", link: "https://redpandavacations.com/" },
    { icon: "/clients/homeneeds.png", link: null },
    { icon: "/clients/lakshmi.png", link: null },
    { icon: "/clients/easydays.png", link: null },
  ];

  const duplicateClients = [...clients, ...clients]; // Duplicate the clients array

  return (
    <Box
      sx={{
        display: "flex",
        userSelect: "none",
        width: "100%",
        overflow: "hidden",
        maskImage:
          "linear-gradient(to right, hsl(0 0% 0% / 0), hsl(0 0% 0% / 1) 10%, hsl(0 0% 0% / 1) 90%, hsl(0 0% 0% / 0))",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexShrink: 0,
          alignItems: "center",
          justifyContent: "space-around",
          whiteSpace: "nowrap",
          width: ["800%", "500%", "250%", "250%", "220%"], // Ensure the width is enough to hold the duplicated content
          animation: [
            `${scrollX} 15s linear infinite`,
            `${scrollX} 18s linear infinite`,
            `${scrollX} 30s linear infinite`,
          ],
          gap: [2],
        }}
      >
        {duplicateClients.map((item, k) =>
          item.link != null ? (
            <Box
              component={Link}
              href={item.link}
              target="_blank"
              color={"inherit"}
              underline="none"
              key={k}
              sx={{
                width: ["17rem"],
                height: ["5rem", "7rem", "5rem", "5rem", "7rem"],
                bgcolor: "#111C3A",
                borderRadius: "1rem",
              }}
            >
              <img
                width="100%"
                height="100%"
                style={{
                  objectFit: "contain",
                  borderRadius: "0.5rem",
                  padding: "5px 20px",
                }}
                src={item.icon}
                alt={`client-${k}`}
              />
            </Box>
          ) : (
            <Box
              key={k}
              sx={{
                width: ["17rem"],
                height: ["5rem", "7rem", "5rem", "5rem", "7rem"],
                bgcolor: "#111C3A",
                borderRadius: "1rem",
              }}
            >
              <img
                width="100%"
                height="100%"
                style={{
                  objectFit: "contain",
                  borderRadius: "0.5rem",
                  padding: "5px 20px",
                }}
                src={item.icon}
                alt={`client-${k}`}
              />
            </Box>
          )
        )}
      </Box>
    </Box>
  );
}

export default ClientsMarquee;
