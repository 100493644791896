import React from "react";
import { FlipWords } from "./ui/flip-words";
import { Box, Typography } from "@mui/material";

export function FlipWordsDemo() {
  const words = ["Develop Software", "Engineer Hardware", "Create Product Design", "Research and Develop"];
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap' }}>
      <FlipWords words={words} />
    </Box>
  );
}
